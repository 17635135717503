/* Projects Section */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&family=Roboto+Mono:wght@300;500&family=Poppins:wght@600&display=swap");

:root {
  --primary-color: #5924ed;
  --secondary-black: #0d081a;
  --secondary-gray: #48464b;
  --body-bg: #efebfa;
}
.containerTwo{
  font-size: 10px;
  /* overflow: hidden; */
  /* padding-top: -180px; */
}

* {
  /* padding: 0;
  margin: 0; */
  box-sizing: border-box;
}
html {
  font-family: "Roboto Mono";
  scroll-behavior: smooth;
}
.containerTwo img {
  width: 100%;
  height: 100%;
}
a {
  color: black;
  text-decoration: none;
}

.containerTwo section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}

.containerTwo p {
  font-size: 16px;
  color: var(--secondary-black);
}

.sectionHeading {
  font-size: clamp(2rem, 10vw, 8rem);
  font-size: 80px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat";
  letter-spacing: 0.2rem;
  text-align: center;
  position: relative;
  margin-bottom: 10rem;
}
.sectionHeading::before {
  content: attr(data-outline);
  
  position: absolute;
  left: 2%;
  top: -20%;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat";
  letter-spacing: 0.2rem;
  color: transparent;
  -webkit-text-stroke: 1.5px #e8e6ee2f;
  z-index: 4;
}
.buttons {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.primaryBtn {
  display: inline-block;
  background-color: var(--primary-color);
  background-color: #4dbdfa;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 600;
  padding: 0.6em 1.6em;
  border-radius: 50px;
}
.buttons .primaryBtn.outline {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.buttons svg {
  width: 50px;
  color: var(--primary-color);
}

#projects {
  padding: 10rem 0;
  width: 95%;
  margin: auto;
 
  font-size: 10px;
  
}
#projects .item {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 15rem;
  font-size: 10px;

}
#projects .item:nth-of-type(even) {
  flex-direction: row-reverse;
}
#projects .item .left {
  flex: 1;
}




#projects .item .right {
  font-size: 10px;
  
  margin-left: -150px;
  flex: 1;
  background-color: var(--body-bg);
  background-color: #07141c;
  color: #fff;
  /* padding: 4rem 3rem;
   */
   padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #00000028;
  z-index: 6;
}
#projects .item:nth-of-type(even) .right {
  margin-left: 0;
}
#projects .item:nth-of-type(even) .left {
  margin-left: -150px;
}
#projects .item .left .img {
  height: 500px;
  overflow: hidden;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 20px #4705fc33;
  position: relative;
}
#projects .item .left .img::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-color: #d5d4d938; */
}
#projects .item .left .img img {
  object-fit: cover;
  transition: 0.3s ease transform;
}
#projects .item:hover .left .img img {
  transform: scale(1.1);
  z-index: 5;
}
#projects .item .right .projectTitle {
  font-size: 20px;
  font-family: "Montserrat";
  margin-bottom: 1rem;
}
#projects .item .right .projectSubTitle {
  font-size: 18px;
  margin-bottom: 2rem;
}
#projects .item .right .projectDesc {
  color: var(--secondary-gray);
  
  color: #fff;
  margin-bottom: 3rem;
  /* border: 1px solid rgb(249, 247, 247); */
  height: 240px;
  
  overflow-y: scroll;
  padding: 5px;
}
#projects .item .right .projectDesc::-webkit-scrollbar {
  width: 3px;
  
}
#projects .item .right .projectDesc::-webkit-scrollbar-thumb {
  background: rgb(13, 165, 230);
  border-radius: 20px;
}
#projects .item .right .projectDesc::-webkit-scrollbar-track {
  
}

#projects .item .right .externalLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: calc(0.6em - 4px) calc(1.6em - 4px);
}
#projects .item .right .externalLink svg {
  width: 25px;
}

@media only screen and (max-width: 768px) {
  #projects .item {
    flex-direction: column;
  }
  #projects .item .left {
    width: 100%;
  }
  #projects .item .right {
    margin-left: 0;
    width: 90%;
    margin-top: -150px;
  }
  #projects .item:nth-of-type(even) {
    flex-direction: column;
  }
  #projects .item:nth-of-type(even) .left {
    margin-left: 0;
  }
}
@media only screen and (max-width: 450px) {
  #projects .item .right .buttons {
    flex-direction: column;
  }
  #projects .item .right .buttons a {
    width: 100%;
    text-align: center;
  }
}

