@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  /* font-family: 'Poppins', sans-serif; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#mainBox{
  /* background: rgb(50, 50, 50); */
  z-index: 900;
  /* padding-bottom: 50px; */
  padding-top: 40px;
  /* padding-bottom: -50px; */
}


/* #mainBox::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f00, #f0f);
  clip-path: circle(30% at right 70%);
}

#mainBox::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#2196f3, #e91e63);
  clip-path: circle(20% at 10% 10%);
}  */

.container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid white; */
  margin: auto;
  z-index: 500;

}
/* .container::before{
  content: '';
  position: absolute;
  top: 0px;
  left: -10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f00, #f0f);
  clip-path: circle(30% at right 70%);
  z-index: -60;
}

.container::after{
  content: '';
  position: absolute;
  top: 20px;
  left: 10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(#2196f3, #e91e63);
  clip-path: circle(20% at 10% 10%);
}  */

.container .card
{
  position: relative;
  width: 220px;
  height: 200px;
  margin-top: 20px;
  box-shadow: 20px 20px 50px rgba(0,0,0,0.5) ;
  border-radius: 15px;
  background: rgba(9, 55, 75, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255,255,255,0.5);
  /* border-bottom: 1px solid rgba(255,255,255,0.5); */
  backdrop-filter: blur(5px);
  z-index: 900;
  /* transition: .3s; */

}
.container .card:hover{
  /* box-shadow :rgba(80, 202, 246, 0.44) 0px 6px 9px;
  box-shadow: rgba(80, 202, 246, 0.24) 0px 3px 8px; */
  box-shadow: rgba(80, 202, 246, 0.4) 0px 0px 0px 2px, rgba(80, 202, 246, 0.65) 0px 4px 6px -1px, rgba(80, 202, 246, 0.08) 0px 1px 0px inset;
 
  background: none;
  /* transition: .3s; */
  transform: translateY(-10px);

}
.container .card .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  transform: translateY(30px);
  opacity: 1;
  transition: 0.5s;
  
}

.container .card .content h3{
  transform: translateY(40px);
  opacity: 0;
  transition: 0.5s;
}

.container .card:hover .content h3{
  transform: translateY(0px);
  opacity: 1;
  transition: 0.5s;
}


.container .card h3{
  margin-top: 8px;
}
.container .card:hover .content{
   transform: translateY(0px);
   opacity: 1;
}

.container .card .content h3{
  font-size: 1.8em;
  color: #fff;
  font-weight: 600;
  z-index: 1;
}
.container .card .content img{
  height: 130px;
  width: 130px;
  z-index: 1;
  object-fit: cover;
}


