@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


body{
    /* margin: 0; */
    /* padding: 0; */
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
    /* cursor: url('../Pages/Images/cursor.png'); */
}
body::-webkit-scrollbar{
  width: 10px;
}
body::-webkit-scrollbar-thumb{
  background: #4dbdfa;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  animation: gradient 15s ease infinite;
  border-radius: 50px;
}
body::-webkit-scrollbar-track{
  background: #000;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.sectionHeading {
    font-size: clamp(32px, 10vw, 128px);
    font-size: 3.125rem;
    color: #4dbdfa;
    text-transform: uppercase;
    font-family: "Montserrat";
    letter-spacing: 3.2px;
    text-align: center;
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
    z-index: 5;
  }
  .sectionHeading::before {
    content: attr(data-outline);
    
    position: absolute;
    left: 1%;
    font-size: 3rem;
    top: 35%;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 800;
    /* letter-spacing: 3.2px; */
    /* color: transparent; */
    /* color: #1a1a1a; */
    color: #4dbdfa;
    opacity: 20%;
    -webkit-text-stroke: .0938rem #e8e6ee2f;
    z-index: 2;
  }

#graph{
  /* border: 1px solid #fff; */
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgb(80, 202, 246); */

}

/* #graph img{
  height: 7.25rem;
} */

.a{
 background-color: #4bb5ee15; 
}